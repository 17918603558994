import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { SharedserviceService } from 'src/app/services';
import { DatePipe} from '@angular/common';
import * as XLSX from 'xlsx';
import { NgxUiLoaderService } from 'ngx-ui-loader';

declare var $: any;

@Component({
  selector: 'app-pg_transactions',
  templateUrl: './pg_transactions.component.html',
  styleUrls: ['./pg_transactions.component.scss'],
  providers: [DatePipe,NgxUiLoaderService]
})
export class PgtransactionsComponent implements OnInit {
  btnsubmitted:boolean=false;
  user:any;
  pagLimit:number=10;
  user_id:any;
  list_data:any=[];
  editbankaccount:any;
  public addNewAccount : FormGroup;
  public editAccount : FormGroup;
  submitted:boolean=false;
  dataId: any;
  public page: number = 1;
  paymentData: any;
  paymentDataTo: any=[];
  searchreportbool:boolean=false;
  start_date:any;
  end_date:any;
  searchFlagReset: boolean = false;
  bodydata: any = {};
  payment_id:any;
  keys: any;
  TotalCount: number;
  TotalCount1: number;
  myapiData: any;
  flag: boolean = false;
  pid:any;
  paymant_status:any;
  today_date = new Date();
  Tedata: any;
  TTotalCount: any;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private datePipe: DatePipe,
    public sharedService: SharedserviceService,
    private fb: FormBuilder,
    public router: Router,
    private toastr: ToastrManager, ) {
      this.user = JSON.parse(localStorage.getItem("user"));

      if (this.user != null) {
        this.user_id = this.user.id;
        // this.role_id = this.user.role_id;
      }
    }

  ngOnInit() {

    this.bodydata = {
      order: "asc",
      page_no: 1,
      page_limit: Number(this.pagLimit),
      start_date: "",
      end_date:"",
      pg_id : "",
      payment_received:"",
      payment_id:"",
      search: ""
     }

    this.pgtransactionList(this.bodydata);
  }


  pgtransactionList(bodydata){
    var self = this;
    this.ngxLoader.start();
     self.sharedService.post('v1/account/pg_transactions',this.bodydata).subscribe((res:any) =>{

      if (res.replyCode == "success") {
        this.ngxLoader.stop();
        this.list_data = res.data;
        // if (res.data.length > 0) {
          // this.keys = Object.keys(this.myapiData[0]);
          this.TotalCount = res.totalRecords;
        // }
        this.list_data.forEach(element => {
          Object.assign(element,{jsonData:this.getJsonParse(element.payment_details)})
        });
      }
     },err=> {
      this.ngxLoader.stop();
      this.toastr.errorToastr(err.error.replyMsg, "Error");
    })

  }

  getJsonParse(jsonObject:any){
    return JSON.parse(jsonObject)
  }

  searchreport(){

    if(this.bodydata.start_date != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.bodydata.end_date != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.bodydata.payment_id != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.bodydata.payment_received != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.bodydata.pg_id != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else{
      this.searchreportbool=true;
    }

  }

  onSearch() {

    this.searchFlagReset = true;
    if (this.bodydata.start_date != '' && this.bodydata.start_date != undefined) {
        this.bodydata.start_date = this.datePipe.transform(new Date(this.bodydata.start_date), 'yyyy-MM-dd');
    }
    if (this.bodydata.end_date != '' && this.bodydata.end_date != undefined) {
        this.bodydata.end_date = this.datePipe.transform(new Date(this.bodydata.end_date), 'yyyy-MM-dd');
    }
    this.pgtransactionList(this.bodydata);

  }

  SearchPayId(){
    if(this.bodydata.payment_id != ''){
      this.searchreport()
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onPageChange(event) {
    this.bodydata['page_no'] = event;
    this.pgtransactionList(this.bodydata);
  }

  onReset() {
    this.searchFlagReset = false;
    this.start_date='';
    this.end_date='';
    this.bodydata.payment_id='';
    this.bodydata.payment_received='';
    this.bodydata.pg_id='';
    this.bodydata['search'] = '';
    this.bodydata['payment_id'] = "",
    this.bodydata['start_date'] = "",
    this.bodydata['end_date'] = "",
    this.bodydata['payment_received'] = "",
    this.bodydata['pg_id'] = "",
    this.pgtransactionList(this.bodydata);

  }

  onChangePaymentStatus(id,payStatus){
    var requestData = {
      id : id,
      status : payStatus
    }

    this.sharedService.post('v1/account/mark_received',requestData).subscribe((res:any) =>{
      if (res.replyCode == "success") {
        this.toastr.successToastr(res.replyMsg, 'Success');
        $('#paymentStatus').modal('hide');
        this.pgtransactionList(this.bodydata);
      }
    },err=> {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
    });

  }

  onChangePaymentStatusModal(id,payStatus){
    this.pid = id;
    this.paymant_status = payStatus;
    $('#paymentStatus').modal('show');
  }

  onPageLimit(value){
    this.pagLimit = value.target.value;
    this.bodydata = {
      order: "asc",
      page_no: 1,
      page_limit: Number(this.pagLimit),
      start_date: "",
      end_date:"",
      pg_id : "",
      payment_received:"",
      payment_id:"",
      search: ""
     }
    this.pgtransactionList(this.bodydata)
  }

  downloadPGTransaction() {
    var request = {
      start_date: this.bodydata.start_date,
      end_date: this.bodydata.end_date,
      page_limit: Number(this.TotalCount),
      page_no: 1,
      order: "asc",
      pg_id : "",
      payment_received:"",
      payment_id:"",
      search: ""

    }

    this.sharedService.post('v1/account/pg_transactions', request).subscribe((res: any) => {
      if (res.replyCode == "success") {
        // this.Tedata = res.data;
        // this.TTotalCount = res.count;
        // this.keys = Object.keys(this.Tedata[0]);

        this.Tedata = res.data;
        this.Tedata.forEach(element => {
          // Object.assign(element,{jsonData:this.getJsonParse(element.payment_details)})
        });
        if (res.data.length > 0) {
          // this.keys = Object.keys(this.myapiData[0]);
          this.TTotalCount = res.totalRecords;
        }

      }
      setTimeout(() => {
        this.exportdata();
      }, 1000);
    }, err => {
      })
  }

  exportdata(): void {
    /* table id is passed over here */
    let element = document.getElementById('contentToConvert');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element,{dateNF:'dd/MMM/yyyy;@',cellDates:true, raw: true});
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1',);
    /* save to file */
    XLSX.writeFile(wb, 'PG-Txn-Excel-Report.xlsx');
  }


}
