import * as XLSX from 'xlsx';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService, SharedserviceService } from 'src/app/services';
import { Component, OnInit } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { DateAdapter } from '@angular/material';
import { DatePipe } from '@angular/common';
import { ManagedataService } from 'src/app/services/manage-data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrManager } from 'ng6-toastr-notifications';

declare var $: any;

@Component({
  selector: 'app-tl-list',
  templateUrl: './tl-list.component.html',
  styleUrls: ['./tl-list.component.scss'],
  providers: [DatePipe, NgxUiLoaderService],
})
export class TlListCompmonent implements OnInit {
  airport_charges = [];
  gst_charges = [];
  finalAmount = [];
  Type: any;
  Tdata: any; Tedata:any;
  bodydata: any = {};
  keys: any;  forApi: boolean = false
  submitted: boolean = false;  minDate: any;  maxDate: any;  date: any;  dataId: any;
  searchreportbool:boolean=false;  TotalCount: string;  TTotalCount: string;  data: any;
  public page: number = 1;  start = 0;  msg: any;  FormId: any;  flag: boolean = false;  today_date: Date;  userdata:any;
  booking_id: any;  Sectors: any=[];    fromDate: any = '';  toDate: any = '';  fromBookingDate:any=''; toBookingDate:any='';
   fromSalesBookingDate:any=''; toSalesBookingDate:any='';
  paymentMode:any="0";  airline_id:any=0;  depcity:any='';  arrcity:any='';  pnr:any='';  passengerfilter:boolean=false;  p_name:any=''
  inventoryOwner:any='';inventoryOwnerArray=[{ id: '0', name: "Select Owner" }]; partnerId:any=''; partnerIdArray=[{ id: '0', name: "Select Partner" }]; websiteUserId:any='';
  websiteUserIdArray=[{ id: '0', name: "Select User" }];
  public page1: number = 1;

  // request: { fromBookingDate: any; toBookingDate: any; fromDepDate: any; toDepDate: any; soldFrom: string; depCity: any; arrCity: any; paymentMode: any; inventoryOwner: number; partnerId: number; websiteUserId: number; page_limit: string; page_no: number; sortorder: string; pnr:any; p_name:any;withPassenger:any;bookingStatus:1,airline_id:number};

  salesRequest: { fromSalesBookingDate: any; toSalesBookingDate: any; fromDepDate: any; toDepDate: any; soldFrom: string; depCity: any; arrCity: any; paymentMode: any; inventoryOwner: number; partnerId: number; websiteUserId: number; page_limit: string; page_no: number; sortorder: string; pnr:any; p_name:any;withPassenger:any;bookingStatus:1,airline_id:number};

  isLoginUserDetails:any={};
  isTlList: any;
  isTldateData: any;
  listDownload: any;
  imageBaseUrl: any;
  constructor(
    private ngxLoader: NgxUiLoaderService,
    public sharedService: SharedserviceService,
    private fb: FormBuilder,
    public router: Router,
    private toastr: ToastrManager,
    public datepipe: DatePipe,
    private datePipe: DatePipe,
    private dateAdapter: DateAdapter<Date>,
    public commonService: CommonService,
    public activatedRoute: ActivatedRoute,
    public manageService:ManagedataService) {
    this.activatedRoute.params.forEach((urlParams) => {
      this.Type = urlParams['type'];
      this.userdata=JSON.parse(localStorage.getItem("user"));
    });
    this.today_date = new Date()
  }


  public settings = {};

  ngOnInit() {

  this.settings = { singleSelection: true, idField: 'id', textField: 'name', enableCheckAll: false, allowSearchFilter: true, limitSelection: -1, clearSearchFilter: true, itemsShowLimit: 3, searchPlaceholderText: 'Enter Name Here...', closeDropDownOnSelection: true, showSelectedItemsAtTop: false, defaultOpen: false };
    this.onTllist();
    this.manageService.GetLoggedInUserDetails().subscribe((response:any)=>{
      if(Object.keys(response).length>0){
        this.isLoginUserDetails=response;
      }
    })
  }

  onTllist(){
    this.ngxLoader.start();
    this.sharedService.get('/v1/flight/tl_list').subscribe((res:any)=>{
      if(res.replyCode=="success"){
        this.ngxLoader.stop();
        this.isTlList = res.data;
        this.TTotalCount = res.count;
        this.imageBaseUrl = res.imagePath;
      }else{
        this.ngxLoader.stop();
        this.toastr.errorToastr(res.replyMsg)
      }
    })
    this.ngxLoader.stop();
  }

  resetFunction(){
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['dashboard/tallyData']);
  }
  downloadPurchase() {
    var request = {
      from_date:this.datePipe.transform(new Date(this.fromBookingDate), 'yyyy-MM-dd'),
      to_date:this.datePipe.transform(new Date(this.toBookingDate), 'yyyy-MM-dd'),
    }
      this.sharedService.post('v1/purchase', request).subscribe((res: any) => {
        if (res.replyCode == "success") {
          this.Tedata = res.data;
          this.keys = Object.keys(this.Tedata[0]);
          this.TTotalCount = res.count;

          res.data.forEach((element: any) => {
            this.gst_charges.push((element.base_fare*5)/100);
            this.airport_charges.push(((element.base_fare*5)/100)-element.fee_taxes);
            this.finalAmount.push(parseInt(element.base_fare) + parseInt(element.fee_taxes));

          });
        }
        console.log(this.airport_charges,'airport_charges')
        // return;
        setTimeout(() => {
          this.exportdata();
        }, 1000);
      }, err => {
        this.toastr.errorToastr(err.replyMsg)
      })
  }
  exportdata(): void {
    let element = document.getElementById('contentToConvert');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element,{dateNF:'dd/MMM/yyyy;@',cellDates:true, raw: true});
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1',);
    XLSX.writeFile(wb, 'Excel-Purchase-Report.xlsx');
  }


  downloadTlList(list:any){
    var sendrequestdata = {
      flight_id: list.id,
      date: list.departure_date
    }
    this.sharedService.post('v1/flight/date_click', sendrequestdata).subscribe((res) => {
      if (res.replyCode == 'success') {
        if ((res.data).length != 0) {
          this.isTldateData = res.data;
          setTimeout(() => {
            this.exportdataForTList()
          }, 1000);
        } else {
          this.toastr.errorToastr('No Data Found  ', "Error");
        }
      } else {
        // this.toastr.errorToastr(res.replyMsg, "Error");
      }
    }, error => {
      // this.toastr.errorToastr(error.error.replyMsg, "Error");
    });

  }

  exportdataForTList(): void {
    this.ngxLoader.start();
    let element = document.getElementById('contentToConvertS');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, { dateNF: 'dd-MMM-yyyy;@', cellDates: true, raw: true });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    this.ngxLoader.stop();
    XLSX.writeFile(wb, 'Excel-TL-List.xlsx');

  }
  onViewData(flight:any) {
    var sendrequestdata = {
      flight_id: flight.id,
      date: flight.departure_date
    }
    this.sharedService.post('v1/flight/date_click', sendrequestdata).subscribe((res) => {
      if (res.replyCode == 'success') {
        if ((res.data).length != 0) {
          this.isTldateData = res.data;
          $('#listView').modal('show');
        }
        $('#listView').modal('show');
      } else {
        this.toastr.errorToastr(res.replyMsg, "Error");
      }
    }, error => {
      this.toastr.errorToastr(error.error.replyMsg, "Error");
    });
  }
  closeView(){
    $('#listView').modal('hide');
  }

  onPageChange(event) {
    this.bodydata['page'] = event;
    // console.log("event check value===========>>>",event);
    this.onTllist();
  }


}
